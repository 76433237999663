import "./src/bootstrap-custom.scss";

const React = require("react");
const Layout = require("./src/components/layout").default;

export const wrapPageElement = ({ element, ...props }, ...args) => {
  return (
    <Layout name="wrapPageElement" props={props} args={args} mode="browser">
      {element}
    </Layout>
  );
};

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "gatsby";
import "./Footer.scss";

function Footer() {
  return (
    <Container fluid className="flex-column p-0 mt-auto">
      <Container
        fluid
        className="hp-footer d-flex flex-sm-row flex-column justify-content-center align-items-center p-4 p-xs-0"
      >
        <img
          src={process.env.GATSBY_S3_BUCKET_ASSETS + "icons/logo.svg"}
          alt="Logo"
        />
        <h1 className="hp-title--subtitle-half text-light text-center pr-md-2 pr-0 pt-2 pl-0 pl-md-4">
          Weather &amp; Disease Platforms for Agriculture.
          <br />
          Made in New Zealand.
        </h1>
      </Container>
      <Container
        fluid
        className="hp-bar-grey text-light d-flex justify-content-between align-items-center"
      >
        <Container>
          <Row className="my-3">
            <Col className="text-center d-block justify-content-between d-md-flex">
              <Link to={"/about"} className="text-light mx-3 mx-md-0">
                <small>About</small>
              </Link>
              <Link to={"/technology"} className="text-light mx-3 mx-md-0">
                <small>MetWatch Digital Platform</small>
              </Link>
              <Link to={"/disease-models"} className="text-light mx-3 mx-md-0">
                <small>Pest &amp; Disease</small>
              </Link>
              <Link to={"/sustainability"} className="text-light mx-3 mx-md-0">
                <small>Sustainability</small>
              </Link>
              <Link to={"/weather-data"} className="text-light mx-3 mx-md-0">
                <small>Weather Data</small>
              </Link>
              <Link to={"/contact"} className="text-light mx-3 mx-md-0">
                <small>Contact Us</small>
              </Link>
            </Col>
          </Row>
          <Row className="pb-3">
            <Col as={"small"}>
              &copy; Copyright HortPlus N.Z. Ltd 2023. <br />
              All rights reserved.
            </Col>
            <Col className="text-right">
              <a
                className="text-light d-block"
                href="https://www.linkedin.com/company/hortplus"
                target="_blank"
              >
                <small>HortPlus LinkedIn</small>
              </a>

              <Link to={"/privacy"} className="text-light d-block">
                <small>Privacy Policy</small>
              </Link>
              <Link to={"/cookies"} className="text-light d-block">
                <small>Cookies</small>
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  );
}

export default Footer;

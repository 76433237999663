import React from "react";
import { Container } from "react-bootstrap";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import SEO from "./SEO";
import "../styles/layout.scss";
import { Helmet } from "react-helmet";

function Layout(props) {
  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== "undefined";

  if (isBrowser) {
    window.fwSettings = {
      widget_id: 51000002611,
    };
    !(function () {
      if ("function" != typeof window.FreshworksWidget) {
        var n = function () {
          n.q.push(arguments);
        };
        (n.q = []), (window.FreshworksWidget = n);
      }
    })();
  }

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"
        ></script>
        <style type="text/css" media="screen, projection">
          @import
          url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);
        </style>
        <script
          type="text/javascript"
          src="https://aus-widget.freshworks.com/widgets/51000002611.js"
          async
          defer
        ></script>
      </Helmet>
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <SEO />
        <Header />
        <Container fluid className="p-0">
          {props.children}
        </Container>
        <Footer />
      </div>
    </>
  );
}

export default Layout;

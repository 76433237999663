import React, { useState } from "react";
import { Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";
import "./Header.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function Header() {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="hp-nav"
      variant="dark"
      expanded={expanded}
    >
      <div className="hp-nav-inner">
        <Navbar.Brand as={Link} to="/">
          <div>
            <img
              className="hp-logo"
              src={
                process.env.GATSBY_S3_BUCKET_ASSETS + "icons/header_logo.svg"
              }
              alt="HortPlus Logo"
            />
          </div>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="navbar-custom"
          style={{ backgroundImage: faBars }}
          onClick={() =>
            setExpanded((prevExpanded) => (prevExpanded = !prevExpanded))
          }
        />

        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav>
            <Nav.Link
              onClick={() => setExpanded(false)}
              as={Link}
              to="/"
              activeClassName="active"
              className="hp-navbar__item"
            >
              Home
            </Nav.Link>
            <Nav.Link
              onClick={() => setExpanded(false)}
              as={Link}
              to="/about"
              activeClassName="active"
              className="hp-navbar__item"
            >
              About
            </Nav.Link>
            <Nav.Link
              onClick={() => setExpanded(false)}
              as={Link}
              to="/technology"
              activeClassName="active"
              className="hp-navbar__item"
            >
              MetWatch
            </Nav.Link>
            <Nav.Link
              onClick={() => setExpanded(false)}
              as={Link}
              to="/weather-data"
              activeClassName="active"
              className="hp-navbar__item"
            >
              Weather Data
            </Nav.Link>
            <Nav.Link
              onClick={() => setExpanded(false)}
              as={Link}
              to="/support"
              activeClassName="active"
              className="hp-navbar__item"
            >
              Support
            </Nav.Link>
            <Nav.Link
              onClick={() => setExpanded(false)}
              as={Link}
              to="/news"
              activeClassName="active"
              className="hp-navbar__item"
            >
              News
            </Nav.Link>
            <Nav.Link
              onClick={() => setExpanded(false)}
              as={Link}
              to="/contact"
              activeClassName="active"
              className="hp-navbar__item"
            >
              Contact
            </Nav.Link>
            <Nav.Link
              onClick={() => setExpanded(false)}
              as={Link}
              to="/demo"
              activeClassName="active"
              className="hp-navbar__item hp-navbar__item--demo px-3 mt-2 mt-lg-0"
            >
              Try Demo Now
            </Nav.Link>
            <Dropdown alignEnd>
              <Dropdown.Toggle variant="primary hp-navbar__item hp-navbar__item--login px-3 mt-2 mt-lg-0">
                Organisation Login
              </Dropdown.Toggle>

              <Dropdown.Menu className="align-right dropdown-menu-right">
                <Dropdown.Item
                  className="hp-navbar__sub-item"
                  href="https://hortplus.metwatch.nz/"
                  title="HortPlus Weather &amp; Disease Portal"
                >
                  HortPlus
                </Dropdown.Item>
                <Dropdown.Item
                  className="hp-navbar__sub-item"
                  href="https://far.metwatch.nz/"
                  title="Foundation for Arable Research Weather &amp; Disease Portal"
                >
                  FAR
                </Dropdown.Item>
                <Dropdown.Item
                  className="hp-navbar__sub-item"
                  href="https://fruitfed.metwatch.nz/"
                  title="Fruitfed Supplies Weather &amp; Disease Portal"
                >
                  Fruitfed Supplies
                </Dropdown.Item>

                <Dropdown.Item
                  className="hp-navbar__sub-item"
                  href="https://kvh.metwatch.nz/"
                  title="Kiwifruit Vine Health Weather &amp; Disease Portal"
                >
                  KVH
                </Dropdown.Item>
                <Dropdown.Item
                  className="hp-navbar__sub-item"
                  href="https://nzap.metwatch.nz/"
                  title="New Zealand Apples &amp; Pears Weather &amp; Disease Portal"
                >
                  NZ Apples and Pears
                </Dropdown.Item>
                <Dropdown.Item
                  className="hp-navbar__sub-item"
                  href="https://nzppi.metwatch.nz/"
                  title="New Zealand Plant Producers Incorporated Weather &amp; Disease Portal"
                >
                  NZPPI
                </Dropdown.Item>
                <Dropdown.Item
                  className="hp-navbar__sub-item"
                  href="https://onions.metwatch.nz/"
                  title="Onions New Zealand Weather &amp; Disease Portal"
                >
                  Onions NZ
                </Dropdown.Item>
                <Dropdown.Item
                  className="hp-navbar__sub-item"
                  href="https://plantandfood.metwatch.nz/"
                  title="Plant &amp; Food Research Weather &amp; Disease Portal"
                >
                  Plant &amp; Food Research
                </Dropdown.Item>
                <Dropdown.Item
                  className="hp-navbar__sub-item"
                  href="https://summerfruit.metwatch.nz/"
                  title="Summerfruit New Zealand Weather &amp; Disease Portal"
                >
                  Summerfruit NZ
                </Dropdown.Item>
                 <Dropdown.Item
                  className="hp-navbar__sub-item"
                  href="https://vnzi.metwatch.nz/"
                  title="Vegetables New Zealand Weather &amp; Disease Portal"
                >
                  Vegetables NZ
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  className="hp-navbar__sub-item text-secondary"
                  as={Link}
                  to={"/contact"}
                >
                  Not sure where to login? <br />
                  Get in touch
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default Header;

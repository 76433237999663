import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

/** Responsible for the site metadata.  */
const SEO = () => {
  return (
    <Helmet>
      <html lang="en"/>
      <meta charset="utf-8" />
      <link
        rel="icon"
        href={process.env.GATSBY_S3_BUCKET_ASSETS + "icons/favicon.svg"}
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="At HortPlus we build digital decision support platforms for agriculture, horticulture, and other primary-sector industries."
      />
      <meta
        property="og:title"
        content="HortPlus | We Build Weather &amp; Disease Platforms"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://development.dmc64st6fgrjl.amplifyapp.com"
      />
      <meta
        property="og:image"
        content={process.env.GATSBY_S3_BUCKET_ASSETS + "images/ogp.jpg"}
      />
      <link
        rel="apple-touch-icon"
        href={process.env.GATSBY_S3_BUCKET_ASSETS + "icons/logo.svg"}
      />

      {/*
        manifest.json provides metadata used when your web app is installed on a
        user's mobile device or desktop. See https://developers.google.com/web/fundamentals/web-app-manifest/
      */}
      {/* <link rel="manifest" href="%PUBLIC_URL%/manifest.json" /> */}
      {/*     
        Notice the use of %PUBLIC_URL% in the tags above.
        It will be replaced with the URL of the `public` folder during the build.
        Only files inside the `public` folder can be referenced from the HTML.
        
        Unlike "/favicon.ico" or "favicon.ico", "%PUBLIC_URL%/favicon.ico" will
        work correctly both with client-side routing and a non-root public URL.
        Learn how to configure a non-root public URL by running `npm run build`.
      */}

      <title>HortPlus | We Build Weather &amp; Disease Platforms</title>
      <noscript>You need to enable JavaScript to run this app.</noscript>
      <body id="root"/>
      <style type="text/css">
        {`
          body {
            margin: 0;
          }
          
          .loader-container {
            width: 100vw;
            height: 100vh;
            display: flex;
            overflow: hidden;
          }
          
          .loader {
            margin: auto;
            border: 5px dotted #dadada;
            border-top: 5px solid #3498db;
            border-radius: 50%;
            width: 100px;
            height: 100px;
            -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
          }
          
          @-webkit-keyframes spin {
            0% {
              -webkit-transform: rotate(0deg);
            }
            
            100% {
              -webkit-transform: rotate(360deg);
            }
          }
          
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            
            100% {
              transform: rotate(360deg);
            }
          }
          `}
      </style>
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};

/**
props = { title, description, image, article }
const { pathname } = useLocation();
const { site } = useStaticQuery(query);

const {
  defaultTitle,
  titleTemplate,
  defaultDescription,
  siteUrl,
  defaultImage,
} = site.siteMetadata;

const seo = {
  title: title || defaultTitle,
  description: description || defaultDescription,
  image: `${siteUrl}${image || defaultImage}`,
  url: `${siteUrl}${pathname}`,
};
*/

/**
 const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`;
*/

/**
This was just the default code for the SEO, can be removed at some point

<Helmet title={seo.title} titleTemplate={titleTemplate}>
  <meta name="description" content={seo.description} />
  <meta name="image" content={seo.image} />
  {seo.url && <meta property="og:url" content={seo.url} />}
  {(article ? true : null) && <meta property="og:type" content="article" />}
  {seo.title && <meta property="og:title" content={seo.title} />}
  {seo.description && (
    <meta property="og:description" content={seo.description} />
    )}
    {seo.image && <meta property="og:image" content={seo.image} />}
    <meta name="twitter:card" content="summary_large_image" />
    {twitterUsername && (
      <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
        )}
        {seo.image && <meta name="twitter:image" content={seo.image} />}
        </Helmet> 
*/
